/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Graph from '../../components/UI/Graph/Graph';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions';
import classes from './Dashboard.module.css';

function Dashboard({ leadDashboardData, onFetchLeadsDashboardData }) {
  const timeIntervals = [
    { value: 'days', label: 'Días' },
    { value: 'weeks', label: 'Semanas' },
    { value: 'months', label: 'Meses' },
  ];
  const timeIntervalActivities = [
    { value: 'hours', label: 'Horas' },
    { value: 'days', label: 'Días' },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const leads = leadDashboardData?.leads;

  // Revisar lógica de esto para usar más adelante
  // const uniqueUsers = () =>
  //   leads?.filter((lead, i, leadsArray) => leadsArray.findIndex((v2) => v2.inLeadId === lead.inLeadId) === i);

  useEffect(() => {
    onFetchLeadsDashboardData();

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      <div className={classes.NumericInformation}>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Monto en créditos aprobados este mes</h2>
          <span className={classes.NumericDataValue}>{leadDashboardData?.monthlyAprovedCreditsAmoun}</span>
        </div>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Créditos aprobados este mes</h2>
          <span className={classes.NumericDataValue}>{leadDashboardData?.monthlyAprovedCredits}</span>
        </div>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Créditos aprobados históricamente</h2>
          <span className={classes.NumericDataValue}>{leadDashboardData?.historycAprovedCredits}</span>
        </div>
        {/* TODO: por ahora no se va a usar  */}
        {/* <div className={classes.NumericDataDiv}>
            <h2 className={classes.NumericDataTitle}>Usuarios unicos:</h2>
            <span className={classes.NumericDataValue}>{uniqueUsers()?.length}</span>
          </div> */}
      </div>
      <Graph
        timeIntervals={timeIntervals}
        leadsToGraph={leadDashboardData ? leadDashboardData.leads : []}
        attributeToSum="acceptedAmount"
        handleIsLoading={setIsLoading}
        monetaryValue
        dataKey="Monto"
        barSize={40}
        tick
      />
      <Graph
        timeIntervals={timeIntervalActivities}
        leadsToGraph={leadDashboardData ? leadDashboardData.leads : []}
        handleIsLoading={setIsLoading}
        dataKey="Actividad"
        barSize={20}
        customLabelClass={classes.CustomLabel}
        tick={false}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ leadDashboardData: state.leads.leadDashboardData });

const mapDispatchToProps = (dispatch) => ({
  onFetchLeadsDashboardData: () => dispatch(actions.getLeadDashboardData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
